<template>
  <div class="">
    <van-nav-bar left-arrow :title="$t('masterdata.详情')" fixed @click-left="$router.go(-1)" />
    <van-collapse v-model="activeNames" accordion style='margin-top: 55px'>
      <div class="splitTitle">{{ $t('masterdata.基本信息') }}</div>
      <van-collapse-item :title="$t('masterdata.基本信息')" name="1">
        <div class="items">
          <h5>{{ $t('masterdata.供应商分类') }}</h5>
          <p>{{ ruleForm.supplierType | setDict('SUPPLIER_CAT') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.性质') }}</h5>
          <p>{{ ruleForm.supplierNature | setDict('CUSTOMER_NATURE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.供应商简称') }}</h5>
          <p>{{ ruleForm.companyAbbrName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.供应商全称') }}</h5>
          <p>{{ ruleForm.companyUsName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.所在地区') }}</h5>
          <p>
            {{
              (ruleForm.tels? ruleForm.tels:'')
                + (ruleForm.provs? '-'+ruleForm.provs:'')
                + (ruleForm.citys? '-'+ruleForm.citys:'')
            }}
          </p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.注册地址') }}</h5>
          <p>{{ ruleForm.supplierAddress }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.经营地址') }}</h5>
          <p>{{ ruleForm.supplierBusinessAddress }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.企业分类') }}</h5>
          <p>{{ ruleForm.companyType | setDict('SUPPLIER_COM_CAT') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.公司性质') }}</h5>
          <p>{{ ruleForm.companyNature | setDict('ENTERPRISE_NATURE') }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.注册资本') }}(万元)</h5>
          <p>{{ ruleForm.registeredCapital | formatAmount }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.成立时间') }}</h5>
          <p>{{ ruleForm.incorporationDate }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.纳税人唯一识别码') }}</h5>
          <p>{{ ruleForm.taxpayerCode }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.法人代表') }}</h5>
          <p>{{ ruleForm.legalRepresentative }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.合作产品') }}</h5>
          <p>{{ ruleForm.mainProducts }}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('masterdata.联系人')" name="7">
        <div v-if="!ruleForm.supplierContactList || !ruleForm.supplierContactList.length">{{ $t('masterdata.无') }}</div>
        <div v-for="(item, index) in ruleForm.supplierContactList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('masterdata.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.联系人') }}</h5>
            <p>{{ item.contact }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.电话') }}</h5>
            <p>{{ item.telephone }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.传真') }}</h5>
            <p>{{ item.fax }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.邮箱') }}</h5>
            <p>{{ item.email }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>
      <van-collapse-item :title="$t('masterdata.付款信息')" name="2">
        <div v-if="!ruleForm.supplierBankList || !ruleForm.supplierBankList.length">{{ $t('masterdata.无') }}</div>
        <div v-for="(item, index) in ruleForm.supplierBankList" :key="index" class="detailLine">
          <div class="items">
            <h5>{{ $t('masterdata.序号') }}</h5>
            <p>{{ index + 1 }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.账户币种') }}:</h5>
            <p>{{ item.orderCurrency | setDict('CURRENCY_TYPE') }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.开户行') }}:</h5>
            <p>{{ item.bank }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.账号') }}:</h5>
            <p>{{ item.account }}</p>
          </div>
          <div class="items">
            <h5>Swift code:</h5>
            <p>{{ item.swiftCode }}</p>
          </div>
          <div class="items">
            <h5>IBANNO:</h5>
            <p>{{ item.ibanNo }}</p>
          </div>
          <div class="items">
            <h5>{{ $t('masterdata.是否主账号') }}:</h5>
            <p>{{ item.isPrimaryAccount | setDict('PUB_Y_N') }}</p>
          </div>
          <van-divider dashed :style="{ borderColor: '#333' }"/>
        </div>
      </van-collapse-item>

      <div class="splitTitle">{{ $t('masterdata.供应商信用信息') }}</div>
      <div v-for="(supplierOrgCredit, index) in ruleForm.supplierOrgCreditList" :key="index">
        <div v-if="supplierOrgCredit.orgId === activeOrgId">
          <van-collapse-item :title="$t('masterdata.授信申请')" name="3">
            <div class="items">
              <h5>{{ $t('masterdata.建议授信额度') }}(万元)</h5>
              <p>{{ (supplierOrgCredit.suggCreditAmount ? supplierOrgCredit.suggCreditAmount:0) | formatAmount }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.授信期限') }}(年)</h5>
              <p>{{ supplierOrgCredit.creditDdl ? supplierOrgCredit.creditDdl:0 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.申请说明') }}</h5>
              <p>{{ supplierOrgCredit.applicationDesc }}</p>
            </div>
          </van-collapse-item>
          <van-collapse-item :title="$t('masterdata.风控评估')" name="4">
            <div class="items" v-show="!activeOrgIsHeadOrg">
              <h5>{{ $t('masterdata.授信金额') }}USD(万元)</h5>
              <p>{{ (supplierOrgCredit.creditLimitDollar ? supplierOrgCredit.creditLimitDollar:0) | formatAmount }}</p>
            </div>
            <div class="items" v-show="!activeOrgIsHeadOrg">
              <h5>{{ $t('masterdata.汇率') }}</h5>
              <p>{{ (supplierOrgCredit.creditLimitDollarRate ? supplierOrgCredit.creditLimitDollarRate:0) | formatAmount }}%</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.授信金额') }}(万元)</h5>
              <p>{{ (supplierOrgCredit.creditLimit ? supplierOrgCredit.creditLimit:0) | formatAmount }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.授信名单') }}</h5>
              <p>{{ supplierOrgCredit.creditType | setDict('CUSTOMER_LC_NAME_LIST') }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.授信币别') }}</h5>
              <p>{{ supplierOrgCredit.riskCurrency | setDict('CURRENCY_TYPE') }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.信用帐期') }}(天)</h5>
              <p>{{ supplierOrgCredit.creditPeriod ? supplierOrgCredit.creditPeriod:0 }}</p>
            </div>
            <div class="items">
              <h5>{{ $t('masterdata.授信说明') }}</h5>
              <p>{{ supplierOrgCredit.creditDesc }}</p>
            </div>
          </van-collapse-item>
          <van-collapse-item :title="$t('masterdata.中信保授信申请信息')" name="5">
            <div v-if="!ruleForm.supplierCreditApplicationList || !ruleForm.supplierCreditApplicationList.filter(item => item.orgId === activeOrgId).length">{{ $t('masterdata.无') }}</div>
            <div v-for="(item, index) in ruleForm.supplierCreditApplicationList.filter(item => item.orgId === activeOrgId)" :key="index" class="detailLine">
              <div class="items">
                <h5>{{ $t('masterdata.序号') }}</h5>
                <p>{{ index + 1 }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('masterdata.申请日期') }}:</h5>
                <p>{{ item.applicationDate }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('masterdata.批复额度') }}:</h5>
                <p>{{ item.approvalAmount | formatAmount }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('masterdata.有效期') }}:</h5>
                <p>{{ item.effectiveDate }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('masterdata.登记人') }}:</h5>
                <p>{{ item.registerPerson }}</p>
              </div>
              <div class="items">
                <h5>{{ $t('masterdata.登记日期') }}:</h5>
                <p>{{ item.registrationDate }}</p>
              </div>
              <van-divider dashed :style="{ borderColor: '#333' }"/>
            </div>
          </van-collapse-item>
        </div>
      </div>

      <div class="splitTitle">{{ $t('masterdata.系统信息') }}</div>
      <van-collapse-item :title="$t('masterdata.系统信息')" name="6">
        <div class="items">
          <h5>{{ $t('masterdata.制单日期') }}</h5>
          <p>{{ ruleForm.createTime }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.制单人') }}</h5>
          <p>{{ ruleForm.createByName }}</p>
        </div>
        <div class="items">
          <h5>{{ $t('masterdata.修改时间') }}</h5>
          <p>{{ ruleForm.lastmodifiedTime }}</p>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div style="margin: 8px 0;">
      <my-history-panel ref="historyPanel" :pid="processInstanceId"></my-history-panel>
    </div>
    <div v-if="approvalShow">
      <my-approval
        v-if="ruleForm"
        class="approvalFixed"
        :woCodeValue="woCode"
        :businessInfo="ruleForm"
        :detailPageName="$route.name"
        :customSubmitBtnName="customSubmitBtnName"
        :processName="processInstanceName"
        :taskId="taskInstanceId"
        @processCallback="processCallback"
        @checkBeforeProcess="checkBeforeProcess"
        @beforeSubmitCallBack="beforeSubmitCallBack"
        @closeStartCallback="closeStartCallback"
      >
      </my-approval>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import util from "../../../libs/util";
import myHistoryPanel from "@/views/business/components/myHistoryPanel";
import myApproval from "@/views/business/components/myApproval";

const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'));
const getOrgInfo = () => JSON.parse(getUserInfo().org);

export default {
  name: "visitorFieldComponent",
  components: {
    myHistoryPanel,
    myApproval
  },
  data() {
    return {
      targetOrgId: '',
      activeOrgId: this.targetOrgId || getUserInfo().companyId,
      loading: false,
      finished: false,
      activeNames: '1',
      hostName: "",
      task: "",
      personId: "",
      approvalShow: false,
      customSubmitBtnName: ["同意", "不同意"],
      id: "",
      key: "",
      title: "",
      detail: null,
      active: 0,
      // businessKey: "",
      mappingId: "",
      taskInstanceId: "",
      currentNode: "",
      variables: {},
      taskType: "",
      ruleForm: {
        id: "",
        companyUsName: "",
        isRename: "",
        contact: "",
        telPhone: "",
        email: "",
        regionTelName: "",
        supplierAddress: "",
        supplierType: "",
        supplierCode: "",
        operationType: "",
        isInvalid: "N",
        invalidDate: "",
        isCustomer: "N",
        internalRelatedParties: "Y",
        createByName: "",
        lastmodifiedByName: "",
        customerId: "",
        tel: "中国",
        prov: "省份",
        city: "城市",
        remark: "",
        supplierBankList: []
      },
      woCode: "supplierRegistration",
      processInstanceName: "供应商信息"
    };
  },
  computed: {
    // 当前活动的页签账套是总部账套
    activeOrgIsHeadOrg() {
      return this.activeOrgId === '517730389255520257';
    },
    currentOrgName() {
      return getUserInfo().company;
    },
    // 当前登录用户账套授信
    currentOrgCredit() {
      return this.ruleForm.supplierOrgCreditList && this.ruleForm.supplierOrgCreditList.find(item => item.orgId === getUserInfo().companyId) || {};
    },
    // 当前授信申请人
    currentApplyPersonName() {
      // 如果当前登录用户账套授信状态为草稿, 则审批人更改为当前登录用户
      if (this.currentOrgCredit && this.currentOrgCredit.status === 'PREPARING') {
        const { id, cname } = getUserInfo();
        this.currentOrgCredit.applyPersonId = id;
        this.currentOrgCredit.applyPersonName = cname;
      }
      return this.currentOrgCredit.applyPersonName;
    },
    // 当前操作用户账套授信
    targetOrgCredit() {
      return this.ruleForm.supplierOrgCreditList && this.ruleForm.supplierOrgCreditList.find(item => item.orgId === this.targetOrgId) || {};
    },
    targetOrCurrentOrgCredit() {
      return this.targetOrgId ? this.targetOrgCredit : this.currentOrgCredit;
    },
    processInstanceId() {
      return this.targetOrCurrentOrgCredit.processInstanceId;
    },
    businessKey() {
      return this.targetOrCurrentOrgCredit.id;
    },
  },
  props: {},
  methods: {
    getProcessMappingId() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.$myHttp({
        method: "post",
        url: "/microarch/activiti/mapping/findBywoCodeAndOrgDept",
        data: {
          entity: {
            woCode: this.woCode,
            orgId: userInfo.companyId,
            deptId: userInfo.deptId
          }
        }
      }).then(res => {
        if (res.data.ext.code === "2000") {
          this.mappingId = res.data.ext.mappingId;
        } else {
          Toast.fail({
            message: res.data.ext.message,
            type: "error",
            duration: 1500
          });
        }
      });
    },
    handleInitApprovalData({ businessKey, id, taskType }) {
      this.taskInstanceId = id;
      this.taskType = taskType;
      // 通过授信信息查找供应商主体
      const url = '/microarch/masterdata/supplierRegistration/supplierOrgCredit';
      this.$myHttp.get(url, { params: { id: businessKey } }).then(json => {
        this.targetOrgId = json.data.entity.orgId;
        this.ruleForm.id = json.data.entity.headerId;
        this.backfilleditData();
      });
    },
    backfilleditData(id) {
      let _this = this;
      this.$myHttp({
        method: "POST",
        url: "/microarch/masterdata/supplierRegistration/view2",
        data: {
          entity: {
            id: _this.ruleForm.id,
            currentOrgId: getUserInfo().companyId
          }
        }
      }).then(res => {
        // 成功回调方法
        var data = res.data.entity;
        this.ruleForm = data;
        this.variables.activitiData = res.data.entity;
        this.getProcessMappingId();
        this.$refs.historyPanel.getData(this.processInstanceId);
        if (this.$route.query.type) {
          this.approvalShow = true;
        }
      });
    },
    setApprovalData(row) {
      if (row && row.pid) {
        this.ruleForm.id = row.businessKey;
        this.taskInstanceId = row.id;
        this.taskType = row.taskType;
      }
    },
    /**
     * 业务提交之前校验
     * */
    checkBeforeProcess(type) {
      if (type) {
        if (type === "beforeAddsign") {
          this.$refs.demoTemperatureEdit.beforeAddsign();
        } else if (type === "afterAddsign") {
          this.$refs.demoTemperatureEdit.afterAddsign();
        } else if (type === "endProcess") {
          this.$refs.demoTemperatureEdit.endProcess();
        } else if (type === "repelProcess") {
          this.repelProcess();
        } else {
          if (!this.taskInstanceId) {
            var params = {
              businessKey: this.ruleForm.id,
              mappingId: this.mappingId,
              processInstanceName: this.processInstanceName,
              userName: sessionStorage.getItem("userName"),
              variables: this.variables
            };
            this.startProcessAction(params);
          } else {
            this.$refs.demoTemperatureEdit.submit();
          }
        }
      } else {
        Toast.fail({
          message: "数据校验失败",
          type: "warning"
        });
      }
    },
    // 业务发起流程
    startProcessAction(params) {
      if (params.mappingId) {
        params.mappingId = this.mappingId;
      }
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.supplierCode, this.processInstanceName);
      this.$myHttp({
        method: "post",
        url: "/microarch/masterdata/supplierRegistration/start",
        data: params
      })
        .then(res => {
          var backData = res.data.ext;
          if (backData) {
            this.$refs.demoTemperatureEdit.submitLoading = false;
            this.$refs.demoTemperatureEdit.isStart = true;
            this.$refs.demoTemperatureEdit.form.currentNodeName = backData.taskName;
            this.$refs.demoTemperatureEdit.firstTaskName = backData.taskName;
            this.$refs.demoTemperatureEdit.isFirstNode = true;
            this.$refs.demoTemperatureEdit.taskId = backData.taskId;
            this.$refs.demoTemperatureEdit.processInstanceId = backData.processInstanceId;
            this.$refs.demoTemperatureEdit.transactProcess(backData.taskId, backData.processInstanceId);
          } else {
            Toast.fail({
              message: "流程发起异常",
              type: "warning"
            });
            this.$refs.demoTemperatureEdit.submitLoading = false;
          }
        })
        .catch(_ => {
          this.$refs.demoTemperatureEdit.submitLoading = false;
        });
    },
    // 业务提交流程
    submitProcessAction(params) {
      this.processInstanceName = util.getWorkflowCode(this.ruleForm.supplierCode, this.processInstanceName);
      this.$myHttp({
        method: "post",
        url: "/microarch/masterdata/supplierRegistration/submit",
        data: params
      })
        .then(res => {
          if (res.data.ext.success) {
            this.processCallback(null, null, this.taskType);
          } else {
            Toast.fail({
              message: res.data.ext.msg,
              type: "warning"
            });
          }
        })
        .catch(_ => {
        });
    },
    /***
     * 业务撤销流程
     */
    repelProcess() {
      var _this = this;
      _this
        .$confirm(this.$t("myApprovalBtn.confirmRevocationProcess"), {
          cancelButtonClass: "sysBackBtn"
        })
        .then(_ => {
          this.$myHttp({
            method: "post",
            url: "/microarch/masterdata/supplierRegistration/undoProcess",
            data: {
              entity: {
                id: this.ruleForm.id,
                processInstanceId: this.ruleForm.processInstanceId
              }
            }
          })
            .then(res => {
              if (res.data.success === undefined || res.data.success) {
                Toast.fail({
                  message: "撤销成功",
                  type: "success"
                });
                this.processCallback(null, null, this.taskType);
              } else {
                Toast.fail({
                  message: res.data.msg,
                  type: "warning"
                });
              }
            })
            .catch(_ => {
            });
        })
        .catch(_ => {
        });
    },

    /***
     * 关闭发起流程审批窗口
     * 删除流程实例，回滚业务状态
     */
    closeStartCallback(pid) {
      this.$myHttp({
        method: "POST",
        url: "/microarch/masterdata/supplierRegistration/deleteProcess",
        data: {
          entity: {
            id: this.ruleForm.id,
            processInstanceId: pid
          }
        }
      })
        .then(res => {
          this.$refs.demoTemperatureEdit.dialogVisible = false;
          this.$refs.demoTemperatureEdit.taskId = "";
          this.$refs.demoTemperatureEdit.processInstanceId = "";
          this.processCallback(null, null, this.taskType);
        })
        .catch(_ => {
        });
    },

    /**
     * 提交之前校验
     * */
    beforeSubmitCallBack(params) {
      // 业务参数校验 此时可以从params中获取提交审批所有的参数
      // 根据这些参数做相应的判断 如果通过调用流程组件中提交方法
      // 不通过给出相应的提示或者触发其他业务操作
      // let selectUserArray = params.candidateUserIds[params.nextId]
      // console.log(selectUserArray);
      this.submitProcessAction(params);
    },
    /**
     * 流程审批之后业务数据更改
     * */
    processCallback(piid, optionType, taskType) {
      var _this = this;
      // util.closeCurrentTag('sale/return/saleReturnApplyEdit', this);
      // // 成功回调方法
      // if (taskType === 'transated') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/haveList'
      //   });
      // } else if (taskType === 'waitfor') {
      //   _this.$router.push({
      //     name: 'sys/workFlow/toDoList'
      //   });
      // } else {
      //   this.$router.push({
      //     name: 'sale/return/saleReturnApplyList'
      //   });
      //   this.$bus.emit('saleReturnApply-SaveSuccess');
      // }
      this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    let selectRow = JSON.parse(this.$route.query.row);
    if (selectRow) {
      this.ruleForm.id = selectRow.id;
      if (selectRow.businessKey) {
        this.ruleForm.id = selectRow.businessKey;
        this.handleInitApprovalData(selectRow);
      }
    }
    // if (this.ruleForm.id) {
    //   this.backfilleditData(this.ruleForm.id);
    // }

    if (selectRow) {
      this.setApprovalData(selectRow);
    }
  },
  filters: {
    setDict(v, dictName) {
      return util.setDict(v, dictName);
    },
    formatAmount(v, decimal = 2, isCovering = true) {
      return util.formatAmount(v, decimal, isCovering);
    }
  }
};
</script>

<style lang="less" scoped>
.top {
  overflow: hidden;

  .left-col {
    float: left;
    width: 60%;
  }

  .img {
    float: right;
  }
}

.visitor-details {
  p {
    color: #aab2bd;
  }

  h5 {
    font-weight: bold;
  }
}

.img {
  width: 40%;
  text-align: center;
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.panel-list {
  .panel {
    margin-top: 5px;
    background: #fff;
  }

  .divline {
    padding: 0 0 5px 0;
  }

  .icon-status {
    width: 50px;
    height: 45px;
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 36px;
    right: 5px;
  }

  .type-0 {
    background-image: url('../../../images/icon-queryStatus-0.png');
  }

  .type-1 {
    background-image: url('../../../images/icon-queryStatus-1.png');
  }

  .type-2 {
    background-image: url('../../../images/icon-queryStatus-2.png');
  }

  .type-3 {
    background-image: url('../../../images/icon-queryStatus-3.png');
  }

  .type-4 {
    background-image: url('../../../images/icon-queryStatus-4.png');
  }
}

.table {
  margin-top: 5px;

  td {
    background: #f6f6f6;
    height: auto;
    padding: 8px 5px;
    border-bottom: 5px solid #fff;
  }
}

.personal-table {
  td {
    text-align: left;
    word-break: break-all;
  }

  img {
    vertical-align: middle;
  }

  .idcard {
    font-size: 12px;
    padding: 0;
  }
}

.car-table {
  td {
    text-align: center;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.visit-record {
  padding: 2px 0 0;

  ul {
    li {
      padding: 3px 0;
    }
  }
}
</style>
